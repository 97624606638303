<app-card-with-timeframe
    *tuiLet="isLoading$ | async as isLoading"
    [isTop]="true"
  [title]="title"
  [hint]="hint"
  [timeframe]="(isPercent ? dataWithPercent?.timeframe : data?.timeframe) || '1d'"
  (changeTimeframe)="onChangeTimeframe($event)"
  class="rating-card">
  @if (data) {
    <ol>
        <li *ngFor="let item of data.rating; let index = index">
            <span [tuiHint]="item.name">{{index + 1}}. {{ item.name }}</span>
            @if (item.historicalValue) {
                <app-small-line-chart
                    [historicalValue]="item.historicalValue"
                    [color]="'#34B41F'"></app-small-line-chart>
            } @if (item.currentValue) {
                <tui-chip [size]="'s'" appearance="info">
                    {{ item.currentValue | price }}
                </tui-chip>
            }
        </li>
    </ol> 
  }
  @if (dataWithPercent) {
    <ol>
        <li *ngFor="let item of dataWithPercent.rating; let index = index">
            <span [tuiHint]="item.name">{{index + 1}}. {{ item.name }}</span>
            @if (item.historicalValuePercent) {
                <app-small-line-chart
                    [isPercent]="true"
                    [historicalValue]="item.historicalValuePercent"
                    [color]="item.currentValuePercent > 0 ? '#34B41F' : '#B8474E'">
                </app-small-line-chart>
            } @if (item.currentValuePercent || item.currentValuePercent === 0) {
                <tui-chip
                    [size]="'s'"
                    [appearance]="item.currentValuePercent > 0 ? 'success' : 'error'">
                    <span class="chip-text">{{ item.currentValuePercent }}%</span>
                </tui-chip>
            }
        </li>
    </ol>
  }

  <ng-container *ngIf="isLoading">
    <ol class="skeleton">
        <li *ngFor="let i of ['tttttt', 'ttttttt', 'tttttttttttttttttttt', 'tttttttttt', 'ttttttttttttttt']">
            <div class="item-wrapper" >
                <span [tuiSkeleton]="isLoading!">{{ i }}</span>
                <div class="values">
                
                <app-small-line-chart
                    [tuiSkeleton]="isLoading!"
                    [historicalValue]="{}"
                    [color]="'#34B41F'">
                </app-small-line-chart>
                
                <tui-chip [tuiSkeleton]="isLoading!" [size]="'s'" appearance="info">
                    000
                </tui-chip>
                </div>
            </div>
        </li>
    </ol>
    </ng-container>

</app-card-with-timeframe>
