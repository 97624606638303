import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { AnalyticsEvent } from './analytics-event.type';
import { IAnalyticsService } from './analytics-service.interface';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

// eslint-disable-next-line no-var
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements IAnalyticsService {
  isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public initialize() {
    if (!this.isBrowser) return;
    
    const url = 'https://www.googletagmanager.com/gtag/js?id=';
    const gTagScript = this.document.createElement('script');
    gTagScript.async = true;
    gTagScript.src = `${url}${environment.googleAnalyticsId}`;
    this.document.head.appendChild(gTagScript);

    const dataLayerScript = this.document.createElement('script');
    dataLayerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsId}', {'send_page_view': false});`;
    this.document.head.appendChild(dataLayerScript);
  }

  public event(action: AnalyticsEvent, params?: { [key: string]: string }) {
    if (typeof gtag === 'undefined') return;
    gtag(
      'event',
      action,
      params && {
        ...params,
      }
    );
  }
}
