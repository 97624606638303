import {
  TuiRoot,
  TuiNotification,
} from '@taiga-ui/core';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from './analytics/google-analytics.service';
import {TuiInputModule, TuiTextfieldControllerModule } from '@taiga-ui/legacy';
import { TgBotPopupComponent } from './tg-bot-popup/tg-bot-popup.component';
import { TgBotPopupService } from './services/tg-bot-popup.service';
import { filter, map, Observable } from 'rxjs';

declare global {
  interface Window {
    MSStream: unknown;
  }
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    TuiRoot,
    DashboardComponent,
    TuiInputModule,
    TuiTextfieldControllerModule,
    FormsModule,
    ReactiveFormsModule,
    TuiNotification,
    TgBotPopupComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  isOverlayVisible = false;
  showNotification = true;

  showTgBotPopup$ = this.tgBotPopupService.needToShow$;

  readonly form = new FormGroup({
    search: new FormControl<string>(''),
  });

  utm$: Observable<string>;

  constructor(@Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(TgBotPopupService) private readonly tgBotPopupService: TgBotPopupService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly activatedRoute: ActivatedRoute,
  ) {    
    this.utm$ = this.activatedRoute.queryParams.pipe(
      map(params => params['utm_id']),
      filter(id => !!id),
    )
  }

  ngOnInit(): void {
    if (environment.production) {
      this.googleAnalyticsService.initialize();
    }
  }

  onSearch() {
    if (!this.form.controls.search.value) return;
    this.window.open(`${environment.searchURL}/search?text=${this.form.controls.search.value}&withPhotos=true`, '_blank')?.focus();
  }

  hideKeyboard() {
    (this.document.activeElement as any).blur();
  }

  onFocusChanged() {
    this.isOverlayVisible = !this.isOverlayVisible;
  }

  onCloseNotification() {
    this.showNotification = false;
  }

  onCloseTgBotPush() {
    this.tgBotPopupService.setPushToViewed();
  }
}
