<footer appVisibility (visible)="onElementVisible()">
    <img src="images/blur.jpg" class="blur">
    <img src="images/small-blur.jpg" class="small-blur">
    <div class="footer-content">
        <div class="first-row"><img src="images/star.png" alt="stars"> Скоро добавим новые метрики <img src="images/star.png" alt="stars"></div>
        <div>Подпишись на наш телеграм-канал, чтобы не пропустить</div>
        <a tuiButton [size]="'l'" href="https://t.me/+cKz2Qi-0FVk4N2Ri" class="tg">Перейти в телеграм-канал</a>
        <a href="https://altmetrics.ru/legal/" class="copyright" target="_blank">© 2023-2024 ООО «Альтметрикс»</a>
    </div>
</footer>
