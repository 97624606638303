import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { TTimeframe } from '../models/timeframe.type';
import { TuiChevron, TuiSkeleton, TuiTooltip } from '@taiga-ui/kit';
import { TuiButton, TuiDataList, TuiDropdown, TuiIcon } from '@taiga-ui/core';
import { TuiActiveZone, TuiObscured } from '@taiga-ui/cdk';
import { CommonModule } from '@angular/common';

const timeframeLabels = {
  '1d': '1 день',
  '7d': '7 дней',
  '14d': '14 дней'
};

@Component({
  selector: 'app-card-with-timeframe',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    TuiTooltip,
    TuiDropdown,
    TuiChevron,
    TuiDataList,
    TuiIcon,
    TuiButton,
    TuiSkeleton,
    TuiActiveZone,
    TuiObscured
  ],
  templateUrl: './card-with-timeframe.component.html',
  styleUrl: './card-with-timeframe.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithTimeframeComponent {
  @Input() title: string = '';
  @Input() titleImg: string = '';
  @Input() hint = '';
  @Input() timeframe: TTimeframe = '1d';
  @Input() isLoading = true;
  @Input() isTop = false;

  @Output() changeTimeframe = new EventEmitter<TTimeframe>();
  
  timeframeDropdownOpen = false;
  // timeframeValue: TTimeframe = '1d';

  get timeframeLabel(): string {
    return timeframeLabels[this.timeframe];
  }

  onUpdateTimeframe(value: TTimeframe): void {
    //this.timeframeValue = value;
    this.timeframeDropdownOpen = false;
    this.changeTimeframe.emit(value);
  }

  protected onClick(): void {
    this.timeframeDropdownOpen = !this.timeframeDropdownOpen;
  }

  protected onObscured(obscured: boolean): void {
      if (obscured) {
          this.timeframeDropdownOpen = false;
      }
  }

  protected onActiveZone(active: boolean): void {
      this.timeframeDropdownOpen = active && this.timeframeDropdownOpen;
  }
}
