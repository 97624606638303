import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const TG_BOT_POPUP_KEY = 'dashboard_tg_bot_popup_viewed';

@Injectable({
  providedIn: 'root'
})
export class TgBotPopupService {

    private needToShowSubject = new BehaviorSubject<boolean>(false);
    needToShow$ = this.needToShowSubject.asObservable();

    constructor(@Inject(PLATFORM_ID) private platformId: Object){}

    public showPushIfNeeded():void {
        if (!isPlatformBrowser(this.platformId)) return;
        const isPushedViewed = sessionStorage.getItem(TG_BOT_POPUP_KEY);

        if (isPushedViewed) return;
        this.needToShowSubject.next(true);
    }

    public setPushToViewed(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        sessionStorage.setItem(TG_BOT_POPUP_KEY, JSON.stringify(true));
        this.needToShowSubject.next(false);
    }
}