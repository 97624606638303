import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IRatingData } from '../models/rating-data.model';
import { TTimeframe } from '../models/timeframe.type';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { SmallLineChartComponent } from '../small-line-chart/small-line-chart.component';
import { CardWithTimeframeComponent } from '../card-with-timeframe/card-with-timeframe.component';
import { TuiLet } from '@taiga-ui/cdk';
import { IRatingWithPercent } from '../models/rating-with-percent.model';
import { Observable } from 'rxjs';
import { PricePipe } from '../pipes/price.pipe';
import { TuiChip, TuiSkeleton, TuiTooltip } from '@taiga-ui/kit';
import { TuiHint } from '@taiga-ui/core';

@Component({
  selector: 'app-rating-card',
  standalone: true,
  imports: [
    CommonModule,
    CardWithTimeframeComponent,
    TuiTooltip,
    TuiChip,
    TuiSkeleton,
    TuiLet,
    LineChartComponent,
    SmallLineChartComponent,
    PricePipe,
    TuiHint,
  ],
  templateUrl: './rating-card.component.html',
  styleUrl: './rating-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingCardComponent {
  @Input() title = '';
  @Input() hint = '';
  @Input() isPercent = false;
  @Input() data: IRatingData | null = null;
  @Input() dataWithPercent: IRatingWithPercent | null = null;
  @Input() isLoading$!: Observable<boolean>;

  @Output() changeTimeframe = new EventEmitter<TTimeframe>();

  onChangeTimeframe(timeframe: TTimeframe): void {
    this.changeTimeframe.emit(timeframe);
  }
}
