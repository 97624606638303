<tui-push *tuiPush="open" class="tg-bot-popup" heading="Хотите первыми бесплатно получить доступ к полной версии?" (close)="onClose()">
    <img class="tg-bot-popup__img" src="images/tg-bot.png" />
    <div class="tg-bot-popup__container">
      
      <div class="tg-bot-popup__content">
        <p>
            Переходите в наш телеграм-бот, а мы выдадим ранний доступ, поделимся аналитическими исследованиями и расскажем об обновлениях!
        </p>
        <a
          tuiButton
          size="m"
          type="button"
          appearance="primary"
          target="_blank"
          [href]="linkToTgBot"
          (click)="onClose()"
          class="tg-bot-popup__btn">
          Получить
        </a>
      </div>
    </div>
  </tui-push>
