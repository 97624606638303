import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardMapperService } from './dashboard-mapper.service';
import { TMarketplace } from '../models/marketplace.type';
import { TTimeframe } from '../models/timeframe.type';
import { BehaviorSubject, Observable, defer, finalize, map, of, tap } from 'rxjs';
import { IndexMetric } from '../models/index-metric.model';
import { ISalesVolume } from '../models/sales-volume.model';
import { ILeaderMetricData } from '../models/leader-metric-data.model';
import { IRatingData } from '../models/rating-data.model';
import {
  IRatingWithPercent,
} from '../models/rating-with-percent.model';
import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
  private readonly metricApiUrl = `${environment.apiURL}/metric`;
  private readonly cacheTtl = 60000; // 1 minute in milliseconds

  private loadingSubjects: { [key: string]: BehaviorSubject<boolean> } = {
    purchaseActivityIndex: new BehaviorSubject(false),
    consumerOptimismIndex: new BehaviorSubject(false),
    salesVolume: new BehaviorSubject(false),
    categoriesRevGrowth: new BehaviorSubject(false),
    categoriesMaxRev: new BehaviorSubject(false),
    categoriesMinRev: new BehaviorSubject(false),
    categoryLeaderRev: new BehaviorSubject(false),
    categoryLeaderSales: new BehaviorSubject(false),
    itemsMaxRev: new BehaviorSubject(false),
    itemLeaderRev: new BehaviorSubject(false),
    itemLeaderSales: new BehaviorSubject(false),
  };

  loading$ = {
    purchaseActivityIndex: this.loadingSubjects['purchaseActivityIndex'].asObservable(),
    consumerOptimismIndex: this.loadingSubjects['consumerOptimismIndex'].asObservable(),
    salesVolume: this.loadingSubjects['salesVolume'].asObservable(),
    categoriesRevGrowth: this.loadingSubjects['categoriesRevGrowth'].asObservable(),
    categoriesMaxRev: this.loadingSubjects['categoriesMaxRev'].asObservable(),
    categoriesMinRev: this.loadingSubjects['categoriesMinRev'].asObservable(),
    categoryLeaderRev: this.loadingSubjects['categoryLeaderRev'].asObservable(),
    categoryLeaderSales: this.loadingSubjects['categoryLeaderSales'].asObservable(),
    itemsMaxRev: this.loadingSubjects['itemsMaxRev'].asObservable(),
    itemLeaderRev: this.loadingSubjects['itemLeaderRev'].asObservable(),
    itemLeaderSales: this.loadingSubjects['itemLeaderSales'].asObservable(),
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly dashboardMapper: DashboardMapperService,
    private readonly cacheService: CacheService
  ) {}

  // Активность покупок
  getPurchaseActivityIndex(
    marketplace: TMarketplace,
    timeframe: TTimeframe
  ): Observable<IndexMetric> {
    return this.fetchData(
      'purchase_activity_index',
      { marketplace, timeframe },
      'purchaseActivityIndex',
      data => this.dashboardMapper.fromIndexMetricDTO(data)
    );
  }

  //Оптимизм покупателей
  getConsumerOptimismIndex(
    marketplace: TMarketplace,
    timeframe: TTimeframe
  ): Observable<IndexMetric> {
    
    return this.fetchData(
      'consumer_optimism_index',
      { marketplace, timeframe },
      'consumerOptimismIndex',
      data => this.dashboardMapper.fromIndexMetricDTO(data)
    );
  }

  // Sales Volume
  getSalesVolume(marketplace: TMarketplace, timeframe: TTimeframe): Observable<ISalesVolume> {
    return this.fetchData(
      'sales_volume',
      { marketplace, timeframe },
      'salesVolume',
      data => this.dashboardMapper.fromSalesVolumeDTO(data)
    );
  }

  // Categories with Growing Revenue
  getCategoriesRevGrowth(marketplace: TMarketplace, timeframe: TTimeframe): Observable<IRatingWithPercent> {
    return this.fetchData(
      'categories_rev_growth',
      { marketplace, timeframe },
      'categoriesRevGrowth',
      data => this.dashboardMapper.fromRatingWithPercentDTO(data)
    );
  }

  // Categories with Maximum Revenue
  getCategoriesMaxRev(marketplace: TMarketplace, timeframe: TTimeframe): Observable<IRatingData> {
    return this.fetchData(
      'categories_max_rev',
      { marketplace, timeframe },
      'categoriesMaxRev',
      data => this.dashboardMapper.fromRatingDataDTO(data)
    );
  }

  // Categories with Minimum Revenue
  getCategoriesMinRev(marketplace: TMarketplace, timeframe: TTimeframe): Observable<IRatingData> {
    return this.fetchData(
      'categories_min_rev',
      { marketplace, timeframe },
      'categoriesMinRev',
      data => this.dashboardMapper.fromRatingDataDTO(data)
    );
  }

  // Category Leader by Revenue
  getCategoryLeaderRev(marketplace: TMarketplace, timeframe: TTimeframe): Observable<ILeaderMetricData> {
    return this.fetchData(
      'category_leader_rev',
      { marketplace, timeframe },
      'categoryLeaderRev',
      data => this.dashboardMapper.fromLeaderMetricDataDTO(data)
    );
  }

  // Category Leader by Sales
  getCategoryLeaderSales(marketplace: TMarketplace, timeframe: TTimeframe): Observable<ILeaderMetricData> {
    return this.fetchData(
      'category_leader_sales',
      { marketplace, timeframe },
      'categoryLeaderSales',
      data => this.dashboardMapper.fromLeaderMetricDataDTO(data)
    );
  }

  // Items with Maximum Revenue
  getItemsMaxRev(marketplace: TMarketplace, timeframe: TTimeframe): Observable<IRatingData> {
    return this.fetchData(
      'items_max_rev',
      { marketplace, timeframe },
      'itemsMaxRev',
      data => this.dashboardMapper.fromRatingDataDTO(data)
    );
  }

  // Item Leader by Revenue
  getItemLeaderRev(marketplace: TMarketplace, timeframe: TTimeframe): Observable<ILeaderMetricData> {
    return this.fetchData(
      'item_leader_rev',
      { marketplace, timeframe },
      'itemLeaderRev',
      data => this.dashboardMapper.fromLeaderMetricDataDTO(data)
    );
  }

  // Item Leader by Sales
  getItemLeaderSales(marketplace: TMarketplace, timeframe: TTimeframe): Observable<ILeaderMetricData> {
    return this.fetchData(
      'item_leader_sales',
      { marketplace, timeframe },
      'itemLeaderSales',
      data => this.dashboardMapper.fromLeaderMetricDataDTO(data)
    );
  }

  private fetchData<T>(
    endpoint: string,
    params: { [key: string]: any },
    loadingKey: string,
    mapper: (data: any) => T
  ): Observable<T> {
    const url = this.createUrlWithParams(endpoint, params);

    const cachedData = this.cacheService.get(url);
    if (cachedData) {
      this.loadingSubjects[loadingKey].next(false);
      return of(cachedData);
    }

    return defer(() => {
      this.loadingSubjects[loadingKey].next(true);
      return this.httpClient.get(url).pipe(
        map(mapper),
        tap(data => {
          this.cacheService.set(url, data, this.cacheTtl);
        }),
        finalize(() => {
          this.loadingSubjects[loadingKey].next(false);
        })
      );
    });
  }

  private createUrlWithParams(endpoint: string, params: { [key: string]: any }): string {
    let httpParams = new HttpParams();
    
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });

    const fullUrl = `${this.metricApiUrl}/${endpoint}?${httpParams.toString()}`;
    return fullUrl;
  }
}
