
<app-card-with-timeframe
    [title]="'Объем продаж'"
    [hint]="'Суммарная выручка от проданных товаров'"
    [titleImg]="'images/lightning.png'"
    [timeframe]="data?.timeframe || '1d'"
    (changeTimeframe)="onChangeTimeframe($event)">
    <div class="container" *tuiLet="isLoading$ | async as isLoading">
        <div class="values">
            @if (isLoading || data?.currentValue) {
                <span class="values__value" [tuiSkeleton]="isBrowser && isLoading!">{{ (data?.currentValue | price) || '3000000₽' }}</span>
            }
            @if (isLoading || data?.currentChangePercent) {
                <tui-chip [size]="'s'" [appearance]="(data?.currentChangePercent || 0) > 0 ? 'success' : 'error'" [tuiSkeleton]="isBrowser && isLoading!">
                    <span class="chip-text">{{ data ? (data.currentChangePercent! | number:'0.0-2')  : 0.02 }}%</span>
                </tui-chip>
            }
        </div>
        <app-line-chart  [color]="color" [historicalValue]="data?.historicalValue || {}" [tuiSkeleton]="isBrowser && isLoading!"></app-line-chart>
    </div>

</app-card-with-timeframe>

