import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, NgZone, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { THistoricalValue } from '../models/historical-value.type';
import Chart, { ChartItem } from 'chart.js/auto';
import {v4 as uuidv4} from 'uuid';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-small-line-chart',
  standalone: true,
  imports: [],
  templateUrl: './small-line-chart.component.html',
  styleUrl: './small-line-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallLineChartComponent implements AfterViewInit, OnChanges {
  @Input() historicalValue!: THistoricalValue;
  @Input() isPercent = false;
  @Input() color!: string;
  @Input() sign = '₽';

  myChartId = `id_${uuidv4()}`;
  chart: any;
  isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    private readonly ngZone: NgZone,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    if (!this.isBrowser) return;
    const ctx = (this.document.getElementById(this.myChartId) as HTMLCanvasElement).getContext("2d");
    if (!ctx) return;

    this.ngZone.runOutsideAngular(() => {
      let gradient = ctx.createLinearGradient(0, 0, 0, 24);
      gradient.addColorStop(0, this.color); 
      gradient.addColorStop(1, 'rgba(255, 255, 255, 10)');

      this.chart = new Chart(ctx as ChartItem, {
        type: 'line',
        data: {
          labels: this.getValues(),
          datasets: [{
            label: '',
            data: this.getValues(),
            fill: true,
            borderColor: this.color,
            borderWidth: 1,
            tension: 0.1,
            pointRadius: 0,
            backgroundColor: gradient
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          hover: {
            mode: 'nearest',
          },
          scales: {
            x: {
              display: false,
              grid: {
                display: false
              },
            },
            y: {
              display: false
            }
          },
        },
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let historicalValueChanges = changes['historicalValue'];
    let colorChanges = changes['color'];

    if (!this.chart || !this.isBrowser) return;

    if (historicalValueChanges && historicalValueChanges.currentValue !== historicalValueChanges.previousValue) {
      (this.chart as Chart).data.datasets[0].data = this.getValues();
    }

    if (colorChanges && colorChanges.currentValue !== colorChanges.previousValue) {
      let gradient = (this.chart as Chart).data.datasets[0].backgroundColor as CanvasGradient;
      gradient.addColorStop(0, this.color);
      gradient.addColorStop(1, 'rgba(255, 255, 255, 10)');

      (this.chart as Chart).data.datasets[0].borderColor = this.color;
    }

    this.ngZone.runOutsideAngular(() => {
      (this.chart as Chart).update();

    });
  }

  onMouseOut() {
    if (!this.chart) return;
    this.ngZone.runOutsideAngular(() => {
      this.chart.tooltip.setActiveElements([], { x: 0, y: 0 });
      this.chart.update();
    });
  }

  private getValues(): [number, number][] {
    let keys = Object.keys(this.historicalValue);
    if (keys.length === 0) return [];
    let firstDate = new Date(keys[0]);
    return keys.map(key => [this.getDiffInDays(firstDate, new Date(key)), this.historicalValue[key] + Math.abs(this.getMin())]);
  }

  private getMin(): number {
    let min = Math.min(...Object.values(this.historicalValue));
    return min < 0 ? min : 0;
  }

  private getDiffInDays(date1: Date, date2: Date): number {
    let diffInTime = date2.getTime() - date1.getTime();
    return Math.round(diffInTime / (1000 * 3600 * 24)) + 1;
  }
}
