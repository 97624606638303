import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
    {
        path: 'ozon',
        component: DashboardComponent,
    },
    {
        path: 'wildberries',
        component: DashboardComponent,
    },
    {
        path: '**',
        redirectTo: 'wildberries'
    }
];
