import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
  pure: true,
  standalone: true
})
export class PricePipe implements PipeTransform {
  transform(value: number | undefined, sign = '₽'): string {
    if (!value) return '';

    if (value >= 1000000000) {
      return `${parseFloat((value / 1000000000).toFixed(1))} млрд ${sign}`;
    } else if (value >= 1000000) {
      return `${parseFloat((value / 1000000).toFixed(1))} млн ${sign}`;
    } else if (value >= 10000) {
      return `${parseFloat((value / 1000).toFixed(1))} тыс ${sign}`;
    } else {
      return `${value} ${sign}`;
    }
  }
}
