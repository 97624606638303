import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { ILeaderMetricData } from '../models/leader-metric-data.model';
import { TTimeframe } from '../models/timeframe.type';
import { CardWithTimeframeComponent } from '../card-with-timeframe/card-with-timeframe.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { Observable } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TuiLet } from '@taiga-ui/cdk';
import { PricePipe } from '../pipes/price.pipe';
import { TuiHint } from '@taiga-ui/core';
import { TuiChip, TuiSkeleton } from '@taiga-ui/kit';

@Component({
  selector: 'app-leader-card',
  standalone: true,
  imports: [
    CommonModule,
    CardWithTimeframeComponent,
    LineChartComponent,
    TuiChip,
    TuiSkeleton,
    TuiLet,
    TuiHint,
    PricePipe,
  ],
  templateUrl: './leader-card.component.html',
  styleUrl: './leader-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaderCardComponent {
  @Input() title = '';
  @Input() titleImg = '';
  @Input() hint = '';
  @Input() data: ILeaderMetricData | null = null;
  @Input() isLoading$!: Observable<boolean>;
  @Input() color!: string;
  @Input() sign = '₽';

  @Output() changeTimeframe = new EventEmitter<TTimeframe>();

  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  onChangeTimeframe(timeframe: TTimeframe): void {
    this.changeTimeframe.emit(timeframe);
  }
}
