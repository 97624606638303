<app-card *tuiLet="(isLoading$ | async) as isLoading">
    <div class="top">
        <div class="content">
            <header>
                <img class="emoji" [src]="titleImg">
                <span>{{ title }}</span>
                <tui-icon
                    tuiHintDirection="bottom"
                    [tuiTooltip]="hint"
                />
            </header>
            @if (isLoading || valueText) {
                <p class="value" [tuiSkeleton]="isBrowser && isLoading!">{{ valueText || 'Text for skeleton' }}</p>
            }
            @if (isLoading || tillUpdateText) {
                <p class="till-update" [tuiSkeleton]="isBrowser && isLoading!">{{ tillUpdateText || 'Text for skeleton' }}</p>
            }
        </div>
        <label tuiProgressLabel [tuiSkeleton]="isBrowser && isLoading!">
            <span class="progress-text">{{ data?.currentValue || '00' }}</span>
            <tui-progress-circle
                size="m"
                [max]="100"
                [style.color]="progressColor"
                [value]="data?.currentValue || 0"
            ></tui-progress-circle>
        </label>
    </div>
    <div class="timeframe">
        <span class="timeframe__title" [tuiSkeleton]="isBrowser && isLoading!">Период расчета</span>
        <ul>
            <li [tuiSkeleton]="isBrowser && isLoading!">
                <span class="timeframe__value" [style.color]="progressColor">{{ historicalValue1 }}</span>
                <span class="timeframe__text">вчера</span>
            </li>
            <li [tuiSkeleton]="isBrowser && isLoading!">
                <span class="timeframe__value" [style.color]="progressColor">{{ historicalValue7 }}</span>
                <span class="timeframe__text">за 7 дней</span>
            </li>
            <li [tuiSkeleton]="isBrowser && isLoading!">
                <span class="timeframe__value" [style.color]="progressColor">{{ historicalValue14 }}</span>
                <span class="timeframe__text">за 14 дней</span>
            </li>
        </ul>
    </div>
</app-card>
