import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Output, AfterViewInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[appVisibility]',
  standalone: true
})
export class VisibilityDirective implements AfterViewInit, OnDestroy {
  @Output() visible = new EventEmitter<void>();
  private observer!: IntersectionObserver;

  constructor(private el: ElementRef,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {}

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.visible.emit();
        }
      });
    });

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}