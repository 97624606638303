import { provideAnimations } from "@angular/platform-browser/animations";
import { ApplicationConfig, provideZoneChangeDetection, importProvidersFrom, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import {NG_EVENT_PLUGINS} from '@taiga-ui/event-plugins';

import { routes } from './app.routes';
import { Meta, provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from "@angular/common/http";
import { MetrikaModule } from 'ng-yandex-metrika';
import * as Sentry from "@sentry/angular";

const metrikaId: string | number = 'METRIKA_ID';
let APP_ENVIRONMENT = 'APP_ENVIRONMENT_VALUE';

export function initIndexing(
  meta: Meta
): () => Promise<void> {
  return async () => {
      const isProduction = APP_ENVIRONMENT === 'production';

      meta.removeTag('name="robots"');
      meta.addTag({
          name: 'robots',
          content: isProduction ? 'index, follow' : 'noindex, nofollow',
      });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    NG_EVENT_PLUGINS,
    importProvidersFrom(
      MetrikaModule.forRoot([
        {
          id: metrikaId as unknown as number,
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        },
        { id: metrikaId as unknown as number },
      ])
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initIndexing,
      multi: true,
      deps: [Meta],
  },
  ]
};
