import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiButton } from '@taiga-ui/core';
import { VisibilityDirective } from '../directives/visibility.directive';
import { TgBotPopupService } from '../services/tg-bot-popup.service';

@Component({
  selector: 'app-dashboard-footer',
  standalone: true,
  imports: [
    TuiButton,
    VisibilityDirective,
  ],
  templateUrl: './dashboard-footer.component.html',
  styleUrl: './dashboard-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardFooterComponent {

  constructor(@Inject(TgBotPopupService) private readonly tgBotPopupService: TgBotPopupService) {}

  onElementVisible(): void {
    this.tgBotPopupService.showPushIfNeeded();
  }
}
