<tui-root>
<div class="container" >
  <header>
    <div class="logo">
      <img src="images/logo.png" alt="logo Altmetrics">
      <span>Альтметрикс</span>
    </div>
    <form [formGroup]="form" action class="search-input">
      <tui-input
        formControlName="search"
        tuiTextfieldSize="m"
        [tuiTextfieldCleaner]="true"
        tuiTextfieldIconLeft="search"
        [tuiTextfieldLabelOutside]="true"
        (keydown.enter)="onSearch()"
        (keyup.enter)="hideKeyboard()"
        (focusedChange)="onFocusChanged()">
        Искать товары
      </tui-input>
    </form>
  </header>
  @if (showNotification) {
    
        <tui-notification
          class="notification"
          size="m"
          status="info"
          (close)="onCloseNotification()"
        >
        <a href="https://t.me/+L2Bt-naHmTg4ZGUy" target="_blank">Подпишись на наш телеграм-канал!</a>
      </tui-notification>
  }
   <router-outlet></router-outlet>
</div>
<app-tg-bot-popup
    [utm]="utm$ | async"
    [open]="(showTgBotPopup$ | async)!" (closePush)="onCloseTgBotPush()"></app-tg-bot-popup>
<div class="overlay" [class.overlay--visible]="isOverlayVisible"></div>
</tui-root>
