import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { CardWithTimeframeComponent } from '../card-with-timeframe/card-with-timeframe.component';
import { ISalesVolume } from '../models/sales-volume.model';
import { TTimeframe } from '../models/timeframe.type';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { Observable } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PricePipe } from '../pipes/price.pipe';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiChip, TuiSkeleton } from '@taiga-ui/kit';


@Component({
  selector: 'app-sales-volume-card',
  standalone: true,
  imports: [CommonModule, CardWithTimeframeComponent, TuiChip, LineChartComponent, TuiSkeleton, TuiLet, PricePipe],
  templateUrl: './sales-volume-card.component.html',
  styleUrl: './sales-volume-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesVolumeCardComponent {
  @Input() data: ISalesVolume | null = null;
  @Input() isLoading$!: Observable<boolean>;
  @Input() color = '#0A84FF';

  @Output() changeTimeframe = new EventEmitter<TTimeframe>();

  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  onChangeTimeframe(timeframe: TTimeframe): void {
    this.changeTimeframe.emit(timeframe);
  }
}
