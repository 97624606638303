import { trigger, transition, query, animateChild, style, animate, stagger } from '@angular/animations';
const TRANSITION = '{{duration}}ms ease-in-out';
const DURATION = {
  params: {
    duration: 300
  }
};
const STAGGER = 300;
/**
 * Add to enable child :leave animation (fixes https://github.com/angular/angular/issues/15753)
 */
const tuiParentAnimation = trigger('tuiParentAnimation', [transition(':leave', [query(':scope > *', [animateChild()], {
  optional: true
})])]);
/**
 * Add on parent to stop initial :enter animation for children
 */
const tuiParentStop = trigger('tuiParentStop', [transition(':enter', [])]);
const tuiHost = trigger('tuiHost', [transition(':enter', [style({
  overflow: 'clip'
}), query(':scope > *', [animateChild()], {
  optional: true
})]), transition(':leave', [query(':scope > *', [animateChild()], {
  optional: true
})])]);
const tuiHeightCollapse = trigger('tuiHeightCollapse', [transition(':enter', [style({
  height: 0
}), animate(TRANSITION, style({
  height: '*'
}))], DURATION), transition(':leave', [style({
  height: '*'
}), animate(TRANSITION, style({
  height: 0
}))], DURATION)]);
const tuiHeightCollapseList = trigger('tuiHeightCollapseList', [transition('* => *', [query(':enter', [style({
  height: 0
}), stagger(STAGGER, [animate(TRANSITION, style({
  height: '*'
}))])], {
  optional: true
}), query(':leave', [style({
  height: '*'
}), stagger(STAGGER, [animate(TRANSITION, style({
  height: 0
}))])], {
  optional: true
})], DURATION)]);
const tuiWidthCollapse = trigger('tuiWidthCollapse', [transition(':enter', [style({
  width: 0
}), animate(TRANSITION, style({
  width: '*'
}))], DURATION), transition(':leave', [style({
  width: '*'
}), animate(TRANSITION, style({
  width: 0
}))], DURATION)]);
const tuiWidthCollapseList = trigger('tuiWidthCollapseList', [transition('* => *', [query(':enter', [style({
  width: 0
}), stagger(STAGGER, [animate(TRANSITION, style({
  width: '*'
}))])], {
  optional: true
}), query(':leave', [style({
  width: '*'
}), stagger(STAGGER, [animate(TRANSITION, style({
  width: 0
}))])], {
  optional: true
})], DURATION)]);
const tuiFadeIn = trigger('tuiFadeIn', [transition(':enter', [style({
  opacity: 0
}), animate(TRANSITION, style({
  opacity: 1
}))], DURATION), transition(':leave', [style({
  opacity: 1
}), animate(TRANSITION, style({
  opacity: 0
}))], DURATION)]);
const tuiFadeInList = trigger('tuiFadeInList', [transition('* => *', [query(':enter', [style({
  opacity: 0
}), stagger(STAGGER, [animate(TRANSITION, style({
  opacity: 1
}))])], {
  optional: true
}), query(':leave', [style({
  opacity: 1
}), stagger(STAGGER, [animate(TRANSITION, style({
  opacity: 0
}))])], {
  optional: true
})], DURATION)]);
const tuiFadeInTop = trigger('tuiFadeInTop', [transition(':enter', [style({
  transform: 'translateY(-10px)',
  opacity: 0
}), animate(TRANSITION, style({
  transform: 'translateY(0)',
  opacity: 1
}))], DURATION), transition(':leave', [style({
  transform: 'translateY(0)',
  opacity: 1
}), animate(TRANSITION, style({
  transform: 'translateY(-10px)',
  opacity: 0
}))], DURATION)]);
const tuiFadeInBottom = trigger('tuiFadeInBottom', [transition(':enter', [style({
  transform: 'translateY(10px)',
  opacity: 0
}), animate(TRANSITION, style({
  transform: 'translateY(0)',
  opacity: 1
}))], DURATION), transition(':leave', [style({
  transform: 'translateY(0)',
  opacity: 1
}), animate(TRANSITION, style({
  transform: 'translateY(10px)',
  opacity: 0
}))], DURATION)]);
const tuiDropdownAnimation = trigger('tuiDropdownAnimation', [transition(':enter', [style({
  transform: 'translateY(-10px)',
  opacity: 0
}), animate(TRANSITION, style({
  transform: 'translateY(0)',
  opacity: 1
}))], DURATION), transition(':leave', [style({
  transform: 'translateY(0)',
  opacity: 1
}), animate(TRANSITION, style({
  transform: 'translateY(-10px)',
  opacity: 0
}))], DURATION)]);
const tuiScaleIn = trigger('tuiScaleIn', [transition(':enter', [style({
  transform: 'scale(0)'
}), animate(TRANSITION, style({
  transform: 'scale(1)'
}))], DURATION), transition(':leave', [style({
  transform: 'scale(1)'
}), animate(TRANSITION, style({
  transform: 'scale(0)'
}))], DURATION)]);
const tuiPop = trigger('tuiPop', [transition(':enter', [style({
  transform: 'scale(0)'
}), animate(TRANSITION, style({
  transform: 'scale(1.1)'
})), animate(TRANSITION, style({
  transform: 'scale(1)'
}))], DURATION), transition(':leave', [style({
  transform: 'scale(1)'
}), animate(TRANSITION, style({
  transform: 'scale(1.1)'
})), animate(TRANSITION, style({
  transform: 'scale(0)'
}))], DURATION)]);
const tuiScaleInList = trigger('tuiScaleInList', [transition('* => *', [query(':enter', [style({
  transform: 'scale(0)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'scale(1)'
}))])], {
  optional: true
}), query(':leave', [style({
  transform: 'scale(1)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'scale(0)'
}))])], {
  optional: true
})], DURATION)]);
const tuiSlideIn = trigger('tuiSlideIn', [transition('* => left', [style({
  transform: 'translateX(-100%)'
}), animate(TRANSITION, style({
  transform: 'translateX(0)'
}))], DURATION), transition('left => *', [style({
  transform: 'translateX(0)'
}), animate(TRANSITION, style({
  transform: 'translateX(-100%)'
}))], DURATION), transition('* => right', [style({
  transform: 'translateX(100%)'
}), animate(TRANSITION, style({
  transform: 'translateX(0)'
}))], DURATION), transition('right => *', [style({
  transform: 'translateX(0)'
}), animate(TRANSITION, style({
  transform: 'translateX(100%)'
}))], DURATION)]);
const tuiSlideInLeft = trigger('tuiSlideInLeft', [transition(':enter', [style({
  transform: 'translateX(-100%)'
}), animate(TRANSITION, style({
  transform: 'translateX(0)'
}))], DURATION), transition(':leave', [style({
  transform: 'translateX(0)'
}), animate(TRANSITION, style({
  transform: 'translateX(-100%)'
}))], DURATION)]);
const tuiSlideInLeftList = trigger('tuiSlideInLeftList', [transition('* => *', [query(':enter', [style({
  transform: 'translateX(-100%)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateX(0)'
}))])], {
  optional: true
}), query(':leave', [style({
  transform: 'translateX(0)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateX(-100%)'
}))])], {
  optional: true
})], DURATION)]);
const tuiSlideInRight = trigger('tuiSlideInRight', [transition(':enter', [style({
  transform: 'translateX(100%)'
}), animate(TRANSITION, style({
  transform: 'translateX(0)'
}))], DURATION), transition(':leave', [style({
  transform: 'translateX(0)'
}), animate(TRANSITION, style({
  transform: 'translateX(100%)'
}))], DURATION)]);
const tuiSlideInRightList = trigger('tuiSlideInRightList', [transition('* => *', [query(':enter', [style({
  transform: 'translateX(100%)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateX(0)'
}))])], {
  optional: true
}), query(':leave', [style({
  transform: 'translateX(0)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateX(100%)'
}))])], {
  optional: true
})], DURATION)]);
const tuiSlideInTop = trigger('tuiSlideInTop', [transition(':enter', [style({
  transform: 'translate3d(0,{{start}},0)',
  pointerEvents: 'none'
}), animate(TRANSITION, style({
  transform: 'translate3d(0,{{end}},0)'
}))], {
  params: {
    end: 0,
    start: '100%',
    duration: 300
  }
}), transition(':leave', [style({
  transform: 'translate3d(0,{{end}},0)'
}), animate(TRANSITION, style({
  transform: 'translate3d(0,{{start}},0)'
}))], {
  params: {
    end: 0,
    start: '100%',
    duration: 300
  }
})]);
const tuiSlideInTopList = trigger('tuiSlideInTopList', [transition('* => *', [query(':enter', [style({
  transform: 'translateY(100%)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateY(0)'
}))])], {
  optional: true
}), query(':leave', [style({
  transform: 'translateY(0)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateY(100%)'
}))])], {
  optional: true
})], DURATION)]);
const tuiSlideInBottom = trigger('tuiSlideInBottom', [transition(':enter', [style({
  transform: 'translateY(-100%)'
}), animate(TRANSITION, style({
  transform: 'translateY(0)'
}))], DURATION), transition(':leave', [style({
  transform: 'translateY(0)'
}), animate(TRANSITION, style({
  transform: 'translateY(-100%)'
}))], DURATION)]);
const tuiSlideInBottomList = trigger('tuiSlideInBottomList', [transition('* => *', [query(':enter', [style({
  transform: 'translateY(-100%)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateY(0)'
}))])], {
  optional: true
}), query(':leave', [style({
  transform: 'translateY(0)'
}), stagger(STAGGER, [animate(TRANSITION, style({
  transform: 'translateY(-100%)'
}))])], {
  optional: true
})], DURATION)]);

/**
 * Generated bundle index. Do not edit.
 */

export { tuiDropdownAnimation, tuiFadeIn, tuiFadeInBottom, tuiFadeInList, tuiFadeInTop, tuiHeightCollapse, tuiHeightCollapseList, tuiHost, tuiParentAnimation, tuiParentStop, tuiPop, tuiScaleIn, tuiScaleInList, tuiSlideIn, tuiSlideInBottom, tuiSlideInBottomList, tuiSlideInLeft, tuiSlideInLeftList, tuiSlideInRight, tuiSlideInRightList, tuiSlideInTop, tuiSlideInTopList, tuiWidthCollapse, tuiWidthCollapseList };
