import { Injectable } from '@angular/core';
import { IndexMetric } from '../models/index-metric.model';
import { IIndexMetricDTO } from '../models/index-metric-dto.model';
import { ISalesVolumeDTO } from '../models/sales-volume-dto.model';
import { ISalesVolume } from '../models/sales-volume.model';
import { ILeaderMetricDataDTO } from '../models/leader-metric-data-dto.model';
import { ILeaderMetricData } from '../models/leader-metric-data.model';
import { IRatingData, IRatingDataDTO } from '../models/rating-data.model';
import {
  IRatingWithPercent,
  IRatingWithPercentDTO,
} from '../models/rating-with-percent.model';

@Injectable({providedIn: 'root'})
export class DashboardMapperService {
  constructor() {}

  fromIndexMetricDTO(dto: IIndexMetricDTO): IndexMetric {
    return {
      tillNextUpdate: {
        hours: dto.till_next_update.hours,
        minutes: dto.till_next_update.minutes,
      },
      currentValue: dto.current_value,
      historicalValue: dto.historical_value ? {
        '1d': dto.historical_value['1d'],
        '7d': dto.historical_value['7d'],
        '14d': dto.historical_value['14d'],
      } : {
        '1d': 0,
        '7d': 0,
        '14d': 0,
      },
    };
  }

  fromSalesVolumeDTO(dto: ISalesVolumeDTO): ISalesVolume {
    return {
      timeframe: dto.timeframe,
      currentValue: dto.current_value,
      currentChangePercent: dto.current_change_percent,
      historicalValue: dto.historical_value,
    };
  }

  fromLeaderMetricDataDTO(dto: ILeaderMetricDataDTO): ILeaderMetricData {
    return {
      timeframe: dto.timeframe,
      currentValue: dto.current_value,
      currentChangePercent: dto.current_change_percent,
      name: dto.name,
      historicalValue: dto.historical_value,
    };
  }

  fromRatingDataDTO(dto: IRatingDataDTO): IRatingData {
    return {
      timeframe: dto.timeframe,
      rating: dto.rating?.map(item => ({
        name: item.name,
        currentValue: item.current_value,
        historicalValue: item.historical_value,
      })),
    };
  }

  fromRatingWithPercentDTO(dto: IRatingWithPercentDTO): IRatingWithPercent {
    return {
      timeframe: dto.timeframe,
      rating: dto.rating?.map(item => ({
        name: item.name,
        currentValuePercent: item.current_value_percent,
        historicalValuePercent: item.historical_value_percent,
      })),
    };
  }
}
