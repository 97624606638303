<app-card [isTop]="isTop">
    <div class="top">
        <div class="content">
            <header>
                @if (titleImg) {
                  <img class="emoji" [src]="titleImg">
                }
                <span>{{ title }}</span>
                <tui-icon
                    tuiHintDirection="bottom"
                    [tuiTooltip]="hint"
                />
            </header>
            
        </div>
            <button
              tuiButton
              tuiChevron
              [tuiDropdownAlign]="'right'"
              [tuiDropdownMaxHeight]="200"
              [tuiDropdown]="dropDownContent"
              [tuiDropdownManual]="timeframeDropdownOpen"
              [tuiObscuredEnabled]="timeframeDropdownOpen"
              (click)="onClick()"
              (tuiActiveZoneChange)="onActiveZone($event)"
              (tuiObscured)="onObscured($event)"
              type="button"
              class="timeframe-btn"
              appearance="custom-secondary"
              size="xs">
              <span class="dropdown-label">{{
                timeframeLabel
              }}</span>
            </button>

        <ng-template #dropDownContent>
            <tui-data-list [size]="'s'">
              <button
                class="dropdown-btn"
                tuiOption
                size="s"
                (click)="onUpdateTimeframe('1d')">
                1 день
              </button>
              <button
                class="dropdown-btn"
                tuiOption
                size="s"
                (click)="onUpdateTimeframe('7d')">
                7 дней
              </button>
              <button
                class="dropdown-btn"
                tuiOption
                size="s"
                (click)="onUpdateTimeframe('14d')">
                14 дней
              </button>
            </tui-data-list>
          </ng-template>
    </div>
    <ng-content></ng-content>
</app-card>
