<div class="social-networks-tabs">
  <label tuiChip [appearance]="isWbActive ? 'custom-active' : 'custom-neutral'">
    <input
      hidden
      type="checkbox"
      [(ngModel)]="isWbActive"
      (ngModelChange)="onWbClick()" />
    Wildberries
  </label>
  <label
    tuiChip
    [appearance]="isOzoneActive ? 'custom-active' : 'custom-neutral'">
    <input
      hidden
      type="checkbox"
      [(ngModel)]="isOzoneActive"
      (ngModelChange)="onOzonClick()" />
    Ozon
  </label>
</div>
<div class="status-date">
  <span class="status-date__label">Состояние рынка </span>
  <span class="status-date__date">{{ currentDate }}</span>
</div>
<div class="dashboards">
  <section class="common">
    <div class="cards">
      
      <app-index-card
        [title]="'Потребительская активность'"
        [titleImg]="'images/fire.png'"
        [isNeutral]="false"
        [hint]="'Насколько активно клиенты покупают товары'"
        [data]="purchaseActivityIndex$ | async"
        [isLoading$]="purchaseActivityIndexLoading$">
      </app-index-card>

      <app-index-card
        [title]="'Оптимизм покупателей'"
        [titleImg]="'images/smile.png'"
        [isNeutral]="true"
        [hint]="'Готовность клиентов покупать больше товаров/покупать более дорогие товары'"
        [data]="consumerOptimismIndex$ | async"
        [isLoading$]="consumerOptimismIndexLoading$">
      </app-index-card>

      <app-sales-volume-card
        [data]="salesVolume$ | async"
        [isLoading$]="salesVolumeLoading$"
        [color]="mpColor[currentMP]"
        (changeTimeframe)="onSalesVolumeTimeframeChanged($event)">
      </app-sales-volume-card>
    </div>
  </section>
  <section class="category-trends">
    <h1>
      <img class="emoji" src="images/brilliant.png" alt="Тренды категорий" />Тренды категорий
    </h1>
    <div class="cards">
      <app-rating-card
        [title]="'Категории с растущей выручкой'"
        [hint]="'Категории с самым большим темпом роста выручки'"
        [dataWithPercent]="categoriesRevGrowth$ | async"
        [isPercent]="true"
        [isLoading$]="categoriesRevGrowthLoading$"
        (changeTimeframe)="onCategoriesRevGrowthTimeframeChanged($event)">
      </app-rating-card>
      <app-rating-card
        [title]="'Категории с наибольшей выручкой'"
        [hint]="'Категории с самой высокой выручкой'"
        [data]="categoriesMaxRev$ | async"
        [isLoading$]="categoriesMaxRevLoading$"
        (changeTimeframe)="onCategoriesMaxRevTimeframeChanged($event)">
    </app-rating-card>
      <app-rating-card
        [title]="'Категории с наименьшей выручкой'"
        [hint]="'Категории с самой низкой выручкой'"
        [data]="categoriesMinRev$ | async"
        [isLoading$]="categoriesMinRevLoading$"
        (changeTimeframe)="onCategoriesMinRevTimeframeChanged($event)">
    </app-rating-card>
    </div>
  </section>
  <section class="categories-leaders">
    <h1>
      <img class="emoji" src="images/cap.png" alt="Категории-лидеры" />Категории-лидеры
    </h1>
    <div class="cards">
      <app-leader-card
        [title]="'Категория-лидер по выручке'"
        [hint]="'Категория с самой высокой выручкой'"
        [data]="categoryLeaderRev$ | async"
        [isLoading$]="categoryLeaderRevLoading$"
        [color]="mpColor[currentMP]"
        (changeTimeframe)="onCategoryLeaderRevTimeframeChanged($event)">
      </app-leader-card>

      <app-leader-card
        [title]="'Категория-лидер по продажам'"
        [hint]="'Категория с самым высоким количеством продаж'"
        [data]="categoryLeaderSales$ | async"
        [isLoading$]="categoryLeaderSalesLoading$"
        [color]="mpColor[currentMP]"
        [sign]="'шт.'"
        (changeTimeframe)="onCategoryLeaderSalesTimeframeChanged($event)">
      </app-leader-card>
    </div>
  </section>
  <section class="items-leaders">
    <h1>
      <img class="emoji" src="images/medal.png" alt="Товары-лидеры" />Товары-лидеры
    </h1>
    <div class="cards">
      <app-rating-card
        [title]="'Товары с наибольшей выручкой'"
        [hint]="'Товары с самой высокой выручкой'"
        [data]="itemsMaxRev$ | async"
        [isLoading$]="itemsMaxRevLoading$"
        (changeTimeframe)="onItemsMaxRevTimeframeChanged($event)">
    </app-rating-card>
      <app-leader-card
        [title]="'Товар-лидер по выручке'"
        [hint]="'Товар с самой высокой выручкой'"
        [data]="itemLeaderRev$ | async"
        [isLoading$]="itemLeaderRevLoading$"
        [color]="mpColor[currentMP]"
        (changeTimeframe)="onItemLeaderRevTimeframeChanged($event)">
      </app-leader-card>

      <app-leader-card
        [title]="'Товар-лидер по продажам'"
        [hint]="'Товар с самым высоким количеством продаж'"
        [data]="itemLeaderSales$ | async"
        [isLoading$]="itemLeaderSalesLoading$"
        [color]="mpColor[currentMP]"
        [sign]="'шт.'"
        (changeTimeframe)="onItemLeaderSalesTimeframeChanged($event)">
      </app-leader-card>
    </div>
  </section>
</div>
<app-dashboard-footer></app-dashboard-footer>
