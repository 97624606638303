import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiButton } from '@taiga-ui/core';
import {TuiPush} from '@taiga-ui/kit';

@Component({
  selector: 'app-tg-bot-popup',
  standalone: true,
  imports: [CommonModule, TuiPush, TuiButton],
  templateUrl: './tg-bot-popup.component.html',
  styleUrl: './tg-bot-popup.component.scss'
})
export class TgBotPopupComponent {
  @Input() open = false;
  @Input() utm: string | null = null;
  @Output() closePush = new EventEmitter<void>();

  get linkToTgBot(): string {
    return `https://t.me/altmetricsbot` + (this.utm ? `?start=${this.utm}` : '');
  }

  constructor() {

  }

  onClose() {
    this.closePush.emit();
  }
}
