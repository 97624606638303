import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { TuiProgress, TuiSkeleton, TuiTooltip } from '@taiga-ui/kit';
import { IndexMetric } from '../models/index-metric.model';
import { Observable } from 'rxjs';
import { TuiLet } from '@taiga-ui/cdk';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TuiIcon } from '@taiga-ui/core';

@Component({
  selector: 'app-index-card',
  standalone: true,
  imports: [CommonModule, CardComponent, TuiProgress, TuiTooltip, TuiSkeleton, TuiLet, TuiIcon],
  templateUrl: './index-card.component.html',
  styleUrl: './index-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexCardComponent {
  @Input() title: string = '';
  @Input() titleImg: string = '';
  @Input() hint = '';
  @Input() isNeutral = true;
  @Input() data!: IndexMetric | null;
  @Input() isLoading$!: Observable<boolean>;

  get progressColor(): string {
    const val = this.data?.currentValue;
    if (!val) return '#960B00';

    if (val >= 80) {
      return '#33FF12';
    } else if (val >= 60) {
      return '#34B41F'
    } else if (val >= 40) {
      return '#FCBB14';
    } else if (val >= 20) {
      return '#E01100';
    } else {
      return '#960B00';
    }
  }

  get valueText(): string {
    const val = this.data?.currentValue;
    if (!val) return '';

    if (val >= 80) {
      return this.isNeutral ? 'Очень высокое' : 'Очень высокая';
    } else if (val >= 60) {
      return this.isNeutral ? 'Высокое' : 'Высокая'
    } else if (val >= 40) {
      return this.isNeutral ? 'Нейтральное' : 'Нейтральная';
    } else if (val >= 20) {
      return this.isNeutral ? 'Низкое' : 'Низкая';
    } else {
      return this.isNeutral ? 'Очень низкое' : 'Очень низкая';
    }
  }

  get historicalValue1(): number {
    return this.data?.historicalValue['1d'] || 0;
  }

  get historicalValue7(): number {
    return this.data?.historicalValue['7d'] || 0;
  }

  get historicalValue14(): number {
    return this.data?.historicalValue['14d'] || 0;
  }

  get tillUpdateText(): string {
    if (!this.data) return '';

    let hours = this.getHoursEnding(this.data.tillNextUpdate.hours);
    let minutes = this.getMinutesEnding(this.data.tillNextUpdate.minutes);
    return `Обновится через: ${this.data.tillNextUpdate.hours} ${hours} ${this.data.tillNextUpdate.minutes} ${minutes}`;
  }

  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  private getHoursEnding(n: number): string {
    if (n % 100 >= 11 && n % 100 <= 14) {
        return "часов";
    }
    const lastDigit = n % 10;
    if (lastDigit === 1) {
        return "час";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return "часа";
    } else {
        return "часов";
    }
  }

  private getMinutesEnding(n: number): string {
    if (n % 100 >= 11 && n % 100 <= 14) {
        return "минут";
    }
    const lastDigit = n % 10;
    if (lastDigit === 1) {
        return "минута";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return "минуты";
    } else {
        return "минут";
    }
  }
}
