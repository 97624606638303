import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { YandexAnalyticsService } from './yandex-analytics.service';
import { IAnalyticsService } from './analytics-service.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  analyticsServices: Array<IAnalyticsService> = [
    this.googleAnalyticsService,
    this.yandexAnalyticsService,
  ];

  constructor(
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly yandexAnalyticsService: YandexAnalyticsService
  ) {}

  sendMetricsIntervalChangeEvent(metric: string, interval: string, mp: string): void {
    this.analyticsServices.forEach(service => {
      service.event('metrics_interval_change', {
        metric: metric,
        interval: interval,
        mp: mp
      });
    });
  }

  sendMetricsSwitchMPEvent(mp: string): void {
    this.analyticsServices.forEach(service => {
      service.event('metrics_switch_mp', {
        mp: mp,
      });
    });
  }


  sendMetricsGoToTgBot(mp: string): void {
    this.analyticsServices.forEach(service => {
      service.event('metrics_go_to_tg_bot', {
        mp: mp,
      });
    });
  }
}
