import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, NgZone, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { TuiDay} from '@taiga-ui/cdk';
import { THistoricalValue } from '../models/historical-value.type';
import Chart, { ChartItem } from 'chart.js/auto';
import {v4 as uuidv4} from 'uuid';
import { PricePipe } from '../pipes/price.pipe';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';



@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [PricePipe],
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  @Input() historicalValue: THistoricalValue = {};
  @Input() color: string = '';
  @Input() sign = '₽';

  myChartId = `id_${uuidv4()}`;
  chart: any;
  isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    private readonly ngZone: NgZone,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    if (!this.isBrowser) return;
    const ctx = (this.document.getElementById(this.myChartId) as HTMLCanvasElement).getContext("2d");
    if (!ctx) return;

    let chartHeight = ctx.canvas.height;

    let gradient = ctx.createLinearGradient(0, 0, 0, chartHeight - 20);
    gradient.addColorStop(0, this.hexToRgba(this.color, 0.5)); 
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');


    const titleCallback = (tooltipItems: any[]) => {
      const pricePipe = new PricePipe();
      return pricePipe.transform(tooltipItems[0].parsed.y, this.sign);
    };

    const labelCallback = (tooltipItem: any) => {
      let date = new Date(Object.keys(this.historicalValue)[tooltipItem.parsed.x]);
      let formattedDate = format(date, "eeeeee, d MMMM", {
        locale: ru
      });
      return formattedDate;
    };
    this.ngZone.runOutsideAngular(() => {
      this.chart = new Chart(ctx as ChartItem, {
        type: 'line',
        data: {
          labels: this.getXLabels(),
          datasets: [{
            label: '',
            data: this.getValues(),
            fill: true,
            borderColor: this.color,
            borderWidth: 2,
            tension: 0.1,
            pointRadius: 0,
            backgroundColor: gradient
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          //animation: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: titleCallback,
                label: labelCallback,
              },
              displayColors: false,
              intersect: false
            }
          },
          hover: {
            mode: 'nearest',
          },
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                maxRotation: 0, // Prevent rotation
                minRotation: 0,  // Prevent rotation
                callback: (value, index, values) => {
                  let chartWidth = ctx.canvas.width;
                  let totalTicks = values.length;
                  let maxTicks = Math.floor(chartWidth / 50);
      
                  if (index % Math.ceil(totalTicks / maxTicks) === 0 && index !== 0 && index !== totalTicks - 1) {
                    return this.getLabel(Object.keys(this.historicalValue)[value as number]);
                  } else {
                    return '';
                  }
                }
              }
            },
            y: {
              display: false
            }
          },
        },
      });
    });
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    let historicalValueChanges = changes['historicalValue'];
    let colorChanges = changes['color'];

    if (!this.chart || !this.isBrowser) return;

    if (historicalValueChanges && historicalValueChanges.currentValue !== historicalValueChanges.previousValue) {
      (this.chart as Chart).data.datasets[0].data = this.getValues();
      (this.chart as Chart).data.labels = this.getXLabels();
    }

    if (colorChanges && colorChanges.currentValue !== colorChanges.previousValue) {
      let gradient = (this.chart as Chart).data.datasets[0].backgroundColor as CanvasGradient;
      gradient.addColorStop(0, this.hexToRgba(this.color, 0.5));
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

      (this.chart as Chart).data.datasets[0].borderColor = this.color;
    }

    this.ngZone.runOutsideAngular(() => {
      (this.chart as Chart).update();    
    });
  }

  getValues(): [number, number][] {
    let keys = Object.keys(this.historicalValue);
    if (keys.length === 0) return [];
    let firstDate = new Date(keys[0]);
    return keys.map(key => [this.getDiffInDays(firstDate, new Date(key)), this.historicalValue[key]]);
  }

  getXLabels(): Array<string> {
    let keys = Object.keys(this.historicalValue);
    return keys.map((key, index) => {
      let tuiDay = TuiDay.fromUtcNativeDate(new Date(key));
      return `${tuiDay.formattedDayPart}.${tuiDay.formattedMonthPart}`;
    })
  }

  onMouseOut() {
    if (!this.chart) return;
    this.ngZone.runOutsideAngular(() => {
      this.chart.tooltip.setActiveElements([], { x: 0, y: 0 });
      this.chart.update();
    });
  }

  private getLabel(value: string): string {
    let tuiDay = TuiDay.fromUtcNativeDate(new Date(value));
    return `${tuiDay.formattedDayPart}.${tuiDay.formattedMonthPart}`;
  }

  private getDiffInDays(date1: Date, date2: Date): number {
    let diffInTime = date2.getTime() - date1.getTime();
    return Math.round(diffInTime / (1000 * 3600 * 24)) + 1;
  }

  private hexToRgba(hex: string, alpha = 1): string {
    // Remove the hash if present
    hex = hex.replace('#', '');
  
    // Parse the components
    let r, g, b;
  
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else if (hex.length === 8) {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
      alpha = parseInt(hex.substring(6, 8), 16) / 255;
    } else {
      throw new Error('Invalid hex color format');
    }
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
