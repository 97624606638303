import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardComponent } from '../card/card.component';
import { IndexCardComponent } from '../index-card/index-card.component';
import { DashboardApiService } from '../services/dashboard-api.service';
import { IndexMetric } from '../models/index-metric.model';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SalesVolumeCardComponent } from '../sales-volume-card/sales-volume-card.component';
import { ISalesVolume } from '../models/sales-volume.model';
import { TMarketplace } from '../models/marketplace.type';
import { IRatingWithPercent } from '../models/rating-with-percent.model';
import { IRatingData } from '../models/rating-data.model';
import { ILeaderMetricData } from '../models/leader-metric-data.model';
import { TTimeframe } from '../models/timeframe.type';
import { LeaderCardComponent } from '../leader-card/leader-card.component';
import { RatingCardComponent } from '../rating-card/rating-card.component';
import { DashboardFooterComponent } from '../dashboard-footer/dashboard-footer.component';
import { ru } from "date-fns/locale";
import { format } from 'date-fns';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';
import { TuiChip } from '@taiga-ui/kit';
import { TgBotPopupService } from '../services/tg-bot-popup.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    TuiChip,
    FormsModule,
    CardComponent,
    IndexCardComponent,
    SalesVolumeCardComponent,
    LeaderCardComponent,
    RatingCardComponent,
    DashboardFooterComponent,
  ],
  providers: [DashboardApiService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
  isOzoneActive = true;
  isWbActive = false;
  currentMP: TMarketplace = 'wildberries';
  mpColor = {
    'ozon': '#0A84FF',
    'wildberries': '#7B439E'
  };

  currentDate: string;

  purchaseActivityIndex$: Observable<IndexMetric> | undefined;
  consumerOptimismIndex$: Observable<IndexMetric> | undefined;
  salesVolume$: Observable<ISalesVolume> | undefined;
  categoriesRevGrowth$: Observable<IRatingWithPercent> | undefined;
  categoriesMaxRev$: Observable<IRatingData> | undefined;
  categoriesMinRev$: Observable<IRatingData> | undefined;
  categoryLeaderRev$: Observable<ILeaderMetricData> | undefined;
  categoryLeaderSales$: Observable<ILeaderMetricData> | undefined;
  itemsMaxRev$: Observable<IRatingData> | undefined;
  itemLeaderRev$: Observable<ILeaderMetricData> | undefined;
  itemLeaderSales$: Observable<ILeaderMetricData> | undefined;

  purchaseActivityIndexLoading$ = this.dashboardApiService.loading$['purchaseActivityIndex'];
  consumerOptimismIndexLoading$ = this.dashboardApiService.loading$['consumerOptimismIndex'];
  salesVolumeLoading$ = this.dashboardApiService.loading$['salesVolume'];
  categoriesRevGrowthLoading$ = this.dashboardApiService.loading$['categoriesRevGrowth'];
  categoriesMaxRevLoading$ = this.dashboardApiService.loading$['categoriesMaxRev'];
  categoriesMinRevLoading$ = this.dashboardApiService.loading$['categoriesMinRev'];
  categoryLeaderRevLoading$ = this.dashboardApiService.loading$['categoryLeaderRev'];
  categoryLeaderSalesLoading$ = this.dashboardApiService.loading$['categoryLeaderSales'];
  itemsMaxRevLoading$ = this.dashboardApiService.loading$['itemsMaxRev'];
  itemLeaderRevLoading$ = this.dashboardApiService.loading$['itemLeaderRev'];
  itemLeaderSalesLoading$ = this.dashboardApiService.loading$['itemLeaderSales'];

  constructor(
    @Inject(TgBotPopupService) private readonly tgBotPopupService: TgBotPopupService,
    private readonly dashboardApiService: DashboardApiService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
  ){
    
    this.currentDate = format(new Date(), "eeeeee, d MMMM", {
      locale: ru
    });
    this.currentMP = router.url.split('?')[0]?.replace('/','') as TMarketplace;
    this.isOzoneActive = this.currentMP === 'ozon';
    this.isWbActive = this.currentMP === 'wildberries';
    this.getAllData(this.currentMP, '1d');
  }

  getAllData(mp: TMarketplace, timeframe: TTimeframe): void {
    this.purchaseActivityIndex$ = this.dashboardApiService.getPurchaseActivityIndex(mp, timeframe);
    this.consumerOptimismIndex$ = this.dashboardApiService.getConsumerOptimismIndex(mp, timeframe);
    this.salesVolume$ = this.dashboardApiService.getSalesVolume(mp, timeframe);
    this.categoriesRevGrowth$ = this.dashboardApiService.getCategoriesRevGrowth(mp, timeframe);
    this.categoriesMaxRev$ = this.dashboardApiService.getCategoriesMaxRev(mp, timeframe);
    this.categoriesMinRev$ = this.dashboardApiService.getCategoriesMinRev(mp, timeframe);
    this.categoryLeaderRev$ = this.dashboardApiService.getCategoryLeaderRev(mp, timeframe);
    this.categoryLeaderSales$ = this.dashboardApiService.getCategoryLeaderSales(mp, timeframe);
    this.itemsMaxRev$ = this.dashboardApiService.getItemsMaxRev(mp, timeframe);
    this.itemLeaderRev$ = this.dashboardApiService.getItemLeaderRev(mp, timeframe);
    this.itemLeaderSales$ = this.dashboardApiService.getItemLeaderSales(mp, timeframe);
  }

  onOzonClick(){
    this.isOzoneActive = true;
    this.isWbActive = false;
    this.currentMP = 'ozon';
    this.analyticsService.sendMetricsSwitchMPEvent(this.currentMP);
    this.router.navigate([`/${this.currentMP}`]);
    this.tgBotPopupService.showPushIfNeeded();
  }

  onWbClick() {
    this.isWbActive = true;
    this.isOzoneActive = false;
    this.currentMP = 'wildberries';
    this.analyticsService.sendMetricsSwitchMPEvent(this.currentMP);
    this.router.navigate([`/${this.currentMP}`]);
  }

  onSalesVolumeTimeframeChanged(timeframe: TTimeframe): void {
    this.salesVolume$ = this.dashboardApiService.getSalesVolume(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('sales_volume', timeframe);
  }

  onCategoriesRevGrowthTimeframeChanged(timeframe: TTimeframe): void {
    this.categoriesRevGrowth$ = this.dashboardApiService.getCategoriesRevGrowth(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('categories_rev_growth', timeframe);
  }

  onCategoriesMaxRevTimeframeChanged(timeframe: TTimeframe): void {
    this.categoriesMaxRev$ = this.dashboardApiService.getCategoriesMaxRev(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('categories_max_rev', timeframe);
  }

  onCategoriesMinRevTimeframeChanged(timeframe: TTimeframe): void {
    this.categoriesMinRev$ = this.dashboardApiService.getCategoriesMinRev(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('categories_min_rev', timeframe);
  }

  onCategoryLeaderRevTimeframeChanged(timeframe: TTimeframe): void {
    this.categoryLeaderRev$ = this.dashboardApiService.getCategoryLeaderRev(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('category_leader_rev', timeframe);
  }

  onCategoryLeaderSalesTimeframeChanged(timeframe: TTimeframe): void {
    this.categoryLeaderSales$ = this.dashboardApiService.getCategoryLeaderSales(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('category_leader_sales', timeframe);
  }

  onItemsMaxRevTimeframeChanged(timeframe: TTimeframe): void {
    this.itemsMaxRev$ = this.dashboardApiService.getItemsMaxRev(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('items_max_rev', timeframe);
  }

  onItemLeaderRevTimeframeChanged(timeframe: TTimeframe): void {
    this.itemLeaderRev$ = this.dashboardApiService.getItemLeaderRev(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('item_leader_rev', timeframe);
  }

  onItemLeaderSalesTimeframeChanged(timeframe: TTimeframe): void {
    this.itemLeaderSales$ = this.dashboardApiService.getItemLeaderSales(this.currentMP, timeframe);
    this.sendTimeframeChangeEvent('item_leader_sales', timeframe);
  }

  private sendTimeframeChangeEvent(metric: string, timeframe: TTimeframe) {
    const interval = timeframe.replace('d','');
    this.analyticsService.sendMetricsIntervalChangeEvent(metric, interval, this.currentMP);
  }

}
